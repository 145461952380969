import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';
import Slide1 from 'src/slides/desktop/pl/chapter_3/sub_3/slide1';
import SEO from 'src/components/_shared/seo';

import WrapperMobile from 'src/slides/mobile/pl/chapter_3/sub_3/wrapperMobile';
import { isMobile } from 'react-device-detect';

const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Świt nowej Europy | Bitwa Warszawska" lang="pl" description="Zobacz jakie państwa powstały w Europie po I wojnie światowej." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Świt nowej Europy | Bitwa Warszawska" lang="pl" description="Zobacz jakie państwa powstały w Europie po I wojnie światowej." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
